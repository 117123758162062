import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";

import { Creators as appActions } from "./ducks/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";

import Modal from "@material-ui/core/Modal";

import Header from "./Header";
import ErrorAlert from "./ErrorAlert";
import WaitingAlert from "./WaitingAlert";
import Loader from "./Loader";
import Slide from "@material-ui/core/Slide";

import { strings } from "./constants";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(appActions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    data: state.app,
    urlDocumento: state.app.urlDocumento,
    operating: state.app.operating,
    pesquisa: state.app.pesquisa,
    oportunities: state.app.oportunities,
  };
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  group: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100px",
  },
  topBar: {
    //backgroundColor:'yellow',
    width: "100vw",
    position: "fixed",
    top: 0,
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  button: {
    backgroundColor: "white",
    //borderRadius:20,
    textTransform: "none",
  },
  icon: {
    margin: theme.spacing(0.5),
    fontSize: 56,
  },
  groupedButton: {
    backgroundColor: "white",
    borderRadius: 0,
    textTransform: "none",
  },
  surveyContainer: {
    backgroundColor: "white",
    //overflowY:'hidden',
    //maxHeight:'100%',
    flexGrow: 1,
    borderRadius: 8,
    marginBottom: 90,
  },
  survey: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  footer: {
    height: 90,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    padding: theme.spacing(4),
    outline: "none",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

class Welcome extends Component {
  state = {
    waitingAlert: null,
  };

  hideWaitingAlert = () => {
    this.setState({ waitingAlert: null });
  };
  showWaitingAlert = () => {
    this.setState({
      waitingAlert: (
        <WaitingAlert
          errorMessage="O servidor ainda está coletando os dados de venda, por favor tente novamente em instantes."
          onConfirm={this.hideWaitingAlert}
        />
      ),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pesquisa !== this.props.pesquisa && this.props.pesquisa) {
      this.props.history.push("/pesquisa");
    }
    if (prevProps.oportunities !== this.props.oportunities && this.props.oportunities) {
      if (this.props.oportunities.length) {
        this.props.history.push("/oportunidades");
      } else this.showWaitingAlert();
    }
  }

  componentDidMount() {}

  hideAlert = () => {
    this.props.hideAlert_Cluster();
  };

  hideAlertLogin = () => {
    this.props.hideAlert_Login();
  };

  render() {
    const { waitingAlert } = this.state;
    const { error, showAlert_Cluster, showAlert_Login } = this.props.data;
    return (
      <div>
        {waitingAlert}
        {error && showAlert_Cluster && <ErrorAlert errorMessage={error} onConfirm={this.hideAlert} />}
        {error && showAlert_Login && <ErrorAlert errorMessage={error} onConfirm={this.hideAlertLogin} />}
        <StyledCluster {...this.props} />
      </div>
    );
  }
}

const subjects = [
  {
    id: 1,
    name: "Novidades",
    type: 1,
  },
  {
    id: 2,
    name: "Conheça a Essilor",
    type: 1,
  },
  {
    id: 3,
    name: "Materiais de Apoio",
    type: 1,
  },
];

const conectaVantagens = {
  id: 5,
  name: "Conecta Vantagens",
  type: 2,
};
const subjectOportunidade = {
  id: 4,
  name: "Oportunidades de Negócios",
  type: 2, //Google Stuff
};

class Cluster extends Component {
  componentDidMount() {
    const docID = this.props.match.params.docID;
    if (docID) {
      //console.log("docID " + docID);
      this.props.performLogin(docID, this.props.history);
    }
  }

  getNomeCliente(client) {
    return client.nomeCliente;
  }

  clickedTema = (param) => (e) => {
    const subject = param;
    //console.log(subject);
    if (subject.type === 1) {
      this.props.loadSurvey(subject.id);
    } else if (subject.type === 2) {
      switch (subject.id) {
        case 4: {
          //Oportunidades de Negócios
          if (this.props.oportunities && this.props.oportunities.length) {
            this.props.history.push("/oportunidades");
          } else this.props.loadOportunities(subject.id);
          break;
        }
        case 5: {
          //Conecta Vantagens
          this.props.history.push("/conectavantagens");
          break;
        }
        default:
          break;
      }
    }
  };

  render() {
    const { classes } = this.props;
    const client = this.props.data.cli;

    let temas = [...subjects];

    if (client && client.tipoDocumento === "CNPJ") {
      temas.push(subjectOportunidade);
      temas.push(conectaVantagens);
    }

    return (
      <Grid container spacing={0} alignItems="flex-start" justify="center" style={{ paddingTop: 120 }}>
        {client && (
          <>
            <Header label={this.getNomeCliente(client)} style={{ backgroundColor: "#194F7C" }} />
            <Grid item xs={12} sm={12} className={classes.contentContainer}>
              <Box color="white" fontWeight="fontWeightBold">
                <Typography component="h3" variant="h3">
                  {strings[client.perfil].welcome}
                </Typography>
              </Box>
              <Box color="white" m={5} lineHeight="normal" fontSize="h6.fontSize">
                {strings[client.perfil].welcomeMessage}
              </Box>
              <Box m={3} color="white" className={classes.survey}>
                <Slide in={true} timeout={500} direction="up">
                  <Box p={5} style={{ display: "flex", flexDirection: "column" }}>
                    {temas.map((subject, index, originalArray) => {
                      const topBorders = index === 0 ? 4 : 0;
                      const bottomBorders = index === originalArray.length - 1 ? 4 : 0;
                      return (
                        <Button
                          size="large"
                          key={"btn" + index}
                          fullWidth
                          variant="contained"
                          className={classes.groupedButton}
                          style={{
                            borderTopLeftRadius: topBorders,
                            borderTopRightRadius: topBorders,
                            borderBottomLeftRadius: bottomBorders,
                            borderBottomRightRadius: bottomBorders,
                          }}
                          onClick={this.clickedTema(subject)}>
                          {subject.name}
                        </Button>
                      );
                    })}
                  </Box>
                </Slide>
              </Box>
            </Grid>
          </>
        )}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.operating}>
          <div className={classes.modalStyle}>
            <Loader message="Carregando..." />
          </div>
        </Modal>
      </Grid>
    );
  }
}

const StyledCluster = withStyles(styles)(Cluster);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Welcome));
